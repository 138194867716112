<template>
  <div>

    <v-row>
      <v-col cols="12" sm="6" md="6" lg="6">
        <v-card class="card">
          <v-card-text class="pt-1 pb-5">
            <div class="row pt-1 border-bottom">
              <div class="col-md-12">
                <div class="d-flex justify-space-between align-center mt-2">
                  <SvgIcon class="text-xl font-semibold" text="AI Sensy details" style="color: black">
                  </SvgIcon>
                  <v-btn fab x-small class="shadow-0" @click.stop="showConfigPopUp">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <v-layout row wrap>
              <v-flex sm12>
                <div class="account" style="text-align: left">
                  <div class="fleft commercial">
                    <div><strong>Status</strong></div>
                    <div><strong>API Key</strong></div>
                    <div><strong>Campaign Name</strong></div>
                  </div>

                  <div class="fleft commercial">
                    <div>{{ aiSensyData.status_id ? aiSensyData.status_id == 1 ? "ACTIVE" : "DEACTIVE" : "NA" }}</div>
                    <div class="line-clamp-1">{{ aiSensyData.api_key || "NA" }}</div>
                    <div>{{ aiSensyData.campaign_name || "NA" }}</div>
                  </div>

                  <div class="clearfix"></div>
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- SMTP Details  Start-->
    <v-dialog v-model="ai_sensy_dialog" scrollable max-width="650" persistent>
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-card-text class="border-bottom mb-3">
            <div class="row pt-1 border-bottom">
              <div class="col-md-12">
                <div class="d-flex justify-space-between align-center mt-2">
                  <SvgIcon class="text-2xl font-semibold"
                           text="AI Sensy Details"
                           style="color: black">
                  </SvgIcon>
                  <v-btn fab x-small class="shadow-0" @click="closeAccountDetails">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <v-row class="mt-8" dense>
              <v-col cols="12" md="6">
                <label for="">API key</label>
                <v-text-field
                    v-model="aiSensyData.api_key"
                    required
                    outlined
                    background-color="#fff"
                    :rules="[(v) => !!v || 'API key is required']"
                    validate-on-blur
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <label for="">Campaign Name</label>
                <v-text-field
                    v-model="aiSensyData.campaign_name"
                    outlined
                    background-color="#fff"
                    required
                    :rules="[
                        (v) => !!v || 'Campaign Name is required']"
                    validate-on-blur
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <label for="">Select Status</label>
                <v-select
                    v-model="aiSensyData.status_id"
                    item-value="id"
                    item-text="name"
                    required
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    :items="[{ id:1,name:'Activate'},{id:2,name:'Deactivate'}]"
                    :rules="[(v) => !!v || 'Please select the status']"
                    validate-on-blur
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    dense
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                class="ma-2"
                text
                @click="closeAccountDetails"
            >Close
            </v-btn
            >
            <v-btn
                class="ma-2 white--text blue-color"
                text
                @click="updateAiSensyDetail"
            >Update
            </v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- SMTP Details end-->
  </div>
</template>

<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  components: {SvgIcon},
  data() {
    return {
      ai_sensy_dialog: false,
      valid: true,
      aiSensyData: {},
    };
  },
  mounted() {
    if (this.$store.getters.getVenueDetails.status) {
      this.aiSensyData = this.$store.getters.getVenueDetails.data.ai_sensy ? this.$store.getters.getVenueDetails.data.ai_sensy : {};
    }
  },
  methods: {
    updateAiSensyDetail() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      event.preventDefault();
      this.showLoader();
      this.$http
          .post("venues/profile/ai-sensy", this.aiSensyData)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.showSuccess("AI Sensy details updated");
              this.ai_sensy_dialog = false;
              this.hideLoader();
              this.$store.dispatch("loadVenueProfileDetails");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    showConfigPopUp() {
      let data = this.$store.getters.getVenueDetails.data;
      let aiSensy = data ? data.ai_sensy : {};
      if (aiSensy) {
        this.aiSensyData = JSON.parse(JSON.stringify(aiSensy));
      } else {
        this.aiSensyData = {};
      }
      this.ai_sensy_dialog = true;
    },

    closeAccountDetails() {
      this.ai_sensy_dialog = false;
      let data = this.$store.getters.getVenueDetails.data;
      let aiSensy = data ? data.ai_sensy : {};
      if (aiSensy) {
        this.aiSensyData = JSON.parse(JSON.stringify(aiSensy));
      } else {
        this.aiSensyData = {};
      }
      this.$forceUpdate();
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
.form_bg {
  margin-top: 2px;
}

.v-btn--fab.v-btn--fixed,
.v-btn--fab.v-btn--absolute {
  z-index: 0 !important;
}

.documents .v-icon {
  border-radius: 12px;
  background: black;
}

.doc_image {
  margin: 40px;
}

.doc_btn {
  text-align: center;
}

.account {
  padding: 22px 10px;
  text-align: left;
  margin: 0 auto;
  width: 90%;
}

.title_txt {
  color: #fff;
  font-size: 14px;
  padding: 6px;
  background: #066a8c;
  margin: 5px;
  border-radius: 3px;
  text-align: center;
}

.commercial {
  width: 50%;
}

.commercial div {
  padding: 6px;
}

#section4 .card {
  min-height: 230px;
}

.fleft {
  float: left;
}
</style>
