<template>
  <div>
  <v-row>
      <v-col lg="6" sm="12">
         <v-card class="pb-3 pt-3 fill-height rounded-3 shadow-2">
          <v-card-text class="card">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  v-if="checkWritePermission($modules.general.profile.slug)"
                  color="#00b0af"
                  elevation="0"
                  fab
                  dark
                  x-small
                  absolute
                  top
                  right
                  @click="updateVenue"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              Edit
            </v-tooltip>
            <v-row>
              <v-col cols="12" md="4" class="profile-img d-flex align-center">
                <view-image
                    v-if="profile.profile_image"
                    :image="profile.profile_image"
                    :contain="false"
                ></view-image>
              </v-col>
              <v-col md="7">
                <div>
                  <p class="box_meta_title">Venue Name</p>
                    <span class="box_meta_content">{{ profile ? profile.name : "NA" }}</span>
                </div>
                <div class="d-flex justify-space-between">
                  <div>
                    <p class="box_meta_title">Email</p>
                    <span class="box_meta_content">{{ profile ? profile.email : "NA" }}</span>
                  </div>
                  <div>
                    <p class="box_meta_title">Mobile</p>
                    <span class="box_meta_content">{{ profile ? profile.phone : "NA" }}</span>
                  </div>
                </div>
                <div>
                  <p class="box_meta_title">Address</p>
                  <span class="box_meta_content">{{ profile ? profile.phone : "NA" }}</span>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col lg="6" sm="12">
        <v-card class=" fill-height rounded-3 pa-4 shadow-2">
          <h4 class="titleskrews">
            Venue Description
          </h4>
          <p class="font-14">
            {{ profile ? profile.description : "NA" }}
          </p>
        </v-card>
      </v-col>
    </v-row>
    <h3 class="new_heading">Services</h3>
    <v-row v-if="checkReadPermission($modules.general.services.slug)">
      <v-col
        md="3"
        sm="4"
        xl="2"
        class="mt-4"
        v-for="(service, index) in services"
        :key="service.venue_service_id"
      >
        <service-widget
          v-bind="service"
          :key="index"
          @delete="deleteService"
        ></service-widget>
      </v-col>

      <v-col
        lg="1"
        sm="2"
        class="mt-4"
        v-if="checkWritePermission($modules.general.services.slug)"
      >
        <v-card
          height="100"
          color="white"
          class="d-flex rounded-3 flex-column justify-center align-center add-service-btn"
        >
            <v-btn
                color="#ffffff"
                elevation="0"
                style="color:#4FAEAF;"
                fab
                @click.stop="addService"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ServiceWidget from "@/components/Venue/ServiceWidget";
export default {
  components: {
    ServiceWidget,
  },
  computed: {
    profile() {
      if (this.$store.getters.getVenueDetails.status == false) return {};
      let data = this.$store.getters.getVenueDetails.data;
      return data != null ? data.profile : {};
    },
    services() {
      if (this.$store.getters.getVenueDetails.status == false) return [];
      let data = this.$store.getters.getVenueDetails.data;
      return data ? data.services : {};
    },
  },
  methods: {
    updateVenue() {
      this.$emit("updateVenue");
    },
    addService() {
      this.$emit("addService");
    },
    deleteService(data) {
      this.$emit("deleteService", data);
    },
  },
};
</script>
<style scoped>
#section1 .layout {
  min-height: 202px !important;
}
.v-btn--fab.v-btn--fixed,
.v-btn--fab.v-btn--absolute {
  z-index: 0 !important;
}
.titleskrews {
  text-align: left;
  color: #112A46;
  font-size: 18px;
  margin-bottom: 8px;
}
.new_heading {
  text-align: left;
  color: #111111;
  font-size: 24px;
  font-weight: bold;
  padding: 20px 0 0 0;
}
.box_meta_title{
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
  color: #A6A6A6;
  margin-bottom: 2px;
  margin-top: 6px;
}

.box_meta_content{
  display: block;
  margin-bottom: 8px !important;
  font-size: 14px;
  font-weight: 600;
  color: #111111;
}

.profile-img{
  margin-left: 10px;
  padding: 8px;
}

.add-service-btn{
  border: 1px solid #4FAEAF !important;
  background: rgba(79, 174, 175, 0.1) !important;
}
</style>
