<template>
  <v-card color="white" class="rounded-3 shadow-2">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          v-if="checkDeletePermission($modules.general.services.slug)"
          fab
          x-small
          absolute
          top
          right
          elevation="0"
          class="border-0"
          @click="deleteService"
        >
          <DeleteIcon/>
        </v-btn>
      </template>
      Delete
    </v-tooltip>
    <v-card-text class="py-0">
      <div class="d-flex justify-start align-center gap-x-4">
        <div style="width: 50px">
        <view-image
          :image="image_path"
          defaultImage="default"
          :height="100"
          :contain="false"
        ></view-image>
        </div>
      <div class="service_name">
        {{ name }}
      </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import DeleteIcon from "@/assets/images/retail/delete-bg-icon.svg";
export default {
  components: {DeleteIcon},
  props: {
    venue_service_id: { type: Number, default: 0 },
    service_id: { type: Number, default: 0 },
    image_path: { type: String, default: null },
    name: { type: String, default: null },
  },
  computed: {
    ...mapGetters({
      checkDeletePermission: "checkDeletePermission",
    }),
  },
  methods: {
    deleteService() {
      this.$emit("delete", this.service_id);
    },
  },
};
</script>

<style scoped>
.service_name {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: black;
}
</style>