<template>
  <v-layout >
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="4">
        <v-card class="card shadow-2" style="height: 100%">
          <v-card-text class="pa-1">
            <div class="row pt-1 border-bottom">
              <div class="col-md-12">
                <div class="d-flex justify-space-between align-center mt-2">
                  <SvgIcon class="text-xl font-semibold" text="Commercial Licence" style="color: black" >
                  </SvgIcon>
                  <v-btn fab x-small class="shadow-0" @click.stop="openVenueDocument('license')">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>

            <div class="d-flex justify-center align-center mt-4">
              <view-image defaultImage="pdf" :contain="false"></view-image>
            </div>
            <div class="d-flex justify-center align-center">
              <v-btn
                  v-if="
                      checkExportPermission($modules.general.profile.slug) &&
                      documents.license
                    "
                  icon
                  :href="
                      documents.license ? s3BucketURL + documents.license : '#'
                    "
                  target="_blank"
                  color="blue-color"
                  class="ma-2 white--text"
              >
                <v-icon dark color="#00b0af">mdi-download</v-icon>
              </v-btn>
            </div>

          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="6" lg="4">
        <v-card class="card shadow-2"  style="height: 100%">
          <v-card-text class="pa-1">
            <div class="row pt-1 border-bottom">
              <div class="col-md-12">
                <div class="d-flex justify-space-between align-center mt-2">
                  <SvgIcon class="text-xl font-semibold" text="Bank account details" style="color: black" >
                  </SvgIcon>
                  <v-btn fab x-small class="shadow-0" @click="updateBankAccount">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <v-layout wrap>
              <v-flex sm12>
                <div class="account" style="text-align: left">
                  <div class="fleft commercial">
                    <div>
                      <strong>Bank Name</strong>
                    </div>
                    <div>
                      <strong>Bank Branch</strong>
                    </div>
                    <div>
                      <strong>Account Number</strong>
                    </div>
                    <div>
                      <strong>IBAN</strong>
                    </div>
                    <div>
                      <strong>Account Name</strong>
                    </div>
                  </div>

                  <div class="fleft commercial">
                    <div>{{ bankDetails.bank_name || "NA" }}</div>
                    <div>{{ bankDetails.bank_branch || "NA" }}</div>
                    <div>{{ bankDetails.account_number || "NA" }}</div>
                    <div>{{ bankDetails.iban || "NA" }}</div>
                    <div>{{ bankDetails.account_holder || "NA" }}</div>
                  </div>

                  <div class="clearfix"></div>
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="6" lg="4">
        <v-card class="card shadow-2" style="height: 100%">
          <v-card-text class="pa-1">
            <div class="row pt-1 border-bottom">
              <div class="col-md-12">
                <div class="d-flex justify-space-between align-center mt-2">
                  <SvgIcon class="text-xl font-semibold" text="Krews agreement" style="color: black" >
                  </SvgIcon>
                  <v-btn fab x-small class="shadow-0" @click="openVenueDocument('agreement')">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="d-flex justify-center align-center mt-4">
              <view-image defaultImage="pdf" :contain="false"></view-image>
            </div>
            <div class="d-flex justify-center align-center">
              <v-btn
                  icon
                  v-if="
                        checkExportPermission($modules.general.profile.slug) &&
                        documents.agreement
                      "
                  :href="
                        documents.agreement
                          ? s3BucketURL + documents.agreement
                          : '#'
                      "
                  target="_blank"
                  class="ma-2"
              >
                <v-icon dark color="#00b0af" target="_blank"
                >mdi-download
                </v-icon
                >
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Commercial  Start-->
    <v-dialog v-model="documentDialog" scrollable max-width="400" persistent>
      <v-card>
        <!--        <v-card-title class="headline"-->
        <!--          >Update-->
        <!--          {{-->
        <!--            document.type == "license"-->
        <!--              ? "Commercial Licence"-->
        <!--              : "QPortal Agreement"-->
        <!--          }}</v-card-title-->
        <!--        >-->
        <v-card-text class="border-bottom mb-3">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold"
                         :text="`${document.type == 'license' ? 'Commercial Licence ':'QPortal Agreement '}`"
                         style="color: black">
                </SvgIcon>
                <v-btn fab x-small class="shadow-0" @click="documentDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>

          <v-row class="mt-8" dense>
            <v-col cols="12">
              <v-flex
                  xs12
                  class="
                text-xs-left text-sm-left text-md-left text-lg-left
              "
              >
                <v-form ref="form">
                  <label for="">Select Document</label>
                  <v-file-input
                      v-model="document.file"
                      prepend-inner-icon="mdi-paperclip"
                      prepend-icon=""
                      outlined
                      background-color="#fff"
                      validate-on-blur
                      class="q-text-field shadow-0"
                      hide-details="auto"
                      dense
                  >
                    <template v-slot:label>
                    <span v-if="!documents.original_file_name">
                      Select file
                    </span>
                      <span
                          v-if="
                        document.file == null && documents.original_file_name
                      "
                          class="font-weight-bold"
                      >
                      <span
                          style="width: 70%; display: inline-block"
                          class="text-truncate"
                      >{{ documents.original_file_name }}</span
                      >
                      <span
                          style="width: 20%; display: inline-block"
                          class="text-truncate"
                      >.{{
                          documents.original_file_name.split(".")[
                          documents.original_file_name.split(".").length - 1
                              ]
                        }}</span
                      >
                    </span>
                    </template>
                  </v-file-input>
                </v-form>
              </v-flex>
            </v-col>
            <v-col cols="12">
              <label for="">TRN Number*</label>
              <v-text-field
                  v-model="trnNumber"
                  required
                  outlined
                  background-color="#fff"
                  validate-on-blur
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="ma-2"
              text
              @click="documentDialog = false"
          >Close
          </v-btn
          >
          <v-btn
              class="ma-2 white--text blue-color"
              text
              @click="updateVenueDocuments"
          >Update
          </v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Commercial Edit end-->

    <!-- Bank Account  Start-->
    <v-dialog v-model="bank_dialog" scrollable max-width="400" persistent>
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-card-text class="border-bottom mb-3">
            <div class="row pt-1 border-bottom">
              <div class="col-md-12">
                <div class="d-flex justify-space-between align-center mt-2">
                  <SvgIcon class="text-2xl font-semibold" text="Bank Account Details" style="color: black">
                  </SvgIcon>
                  <v-btn fab x-small class="shadow-0" @click="closeAccountDetails">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <v-row class="mt-8" dense>
              <v-col cols="12">
                <label for="">Bank Name*</label>
                <v-text-field
                    v-model="accountData.bank_name"
                    required
                    outlined
                    background-color="#fff"
                    :rules="[(v) => !!v || 'Bank Name is required']"
                    validate-on-blur
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label for="">Bank Branch*</label>
                <v-text-field
                    v-model="accountData.bank_branch"
                    outlined
                    background-color="#fff"
                    required
                    :rules="[(v) => !!v || 'Bank Branch is required']"
                    validate-on-blur
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label for="">Account Number*</label>
                <v-text-field
                    v-model="accountData.account_number"
                    outlined
                    background-color="#fff"
                    required
                    :rules="[
                  (v) => !!v || 'Account Number is required',
                  (v) =>
                    (v && v.length < 20) ||
                    'Account number should be less than 20 characters',
                ]"
                    validate-on-blur
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label for="">IBAN*</label>
                <v-text-field
                    v-model="accountData.iban"
                    outlined
                    background-color="#fff"
                    required
                    :rules="[
                  (v) => !!v || 'IBAN is required',
                  (v) =>
                    (v && v.length < 30) ||
                    'IBAN should be less than 30 characters',
                ]"
                    validate-on-blur
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label for="">Account Name*</label>
                <v-text-field
                    v-model="accountData.account_holder"
                    outlined
                    background-color="#fff"
                    required
                    :rules="[(v) => !!v || 'Account Name is required']"
                    validate-on-blur
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                class="ma-2"
                text
                @click="closeAccountDetails"
            >Close
            </v-btn
            >
            <v-btn
                class="ma-2 white--text blue-color"
                text
                @click="updateAccountsDetail"
            >Update
            </v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- Commercial Edit end-->
  </v-layout>
</template>

<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  components: {SvgIcon},
  data() {
    return {
      accountData: {},
      documentDialog: false,
      bank_dialog: false,
      valid: true,
      document: {},
      trnNumber: null,
    };
  },
  computed: {
    bankDetails() {
      if (this.$store.getters.getVenueDetails.status == false) return {};
      let data = this.$store.getters.getVenueDetails.data;
      return data ? data.bank_details : {};
    },
    documents() {
      if (this.$store.getters.getVenueDetails.status == false) return {};
      let data = this.$store.getters.getVenueDetails.data;
      return data ? data.documents : {};
    },
  },
  mounted() {
    if (this.$store.getters.getVenueDetails.status) {
      this.trnNumber = this.$store.getters.getVenueDetails.data.documents.trn;
    }
  },
  methods: {
    openVenueDocument(type) {
      this.document.type = type;
      if (type == "agreement") {
        this.document.document_type_slug = process.env.VUE_APP_VENUE_AGREEMENT;
      } else if (type == "license") {
        this.document.document_type_slug = process.env.VUE_APP_VENUE_LICENSE;
      }
      this.document.file = null;
      this.documentDialog = true;
    },
    updateVenueDocuments() {
      event.preventDefault();
      if (!this.$refs.form.validate()) {
        this.showError("Please upload a file");
        return;
      }
      let formData = new FormData();
      Object.keys(this.document).forEach((key) => {
        let value = this.document[key];
        if (this.document[key]) {
          formData.append(key, value);
        }
      });
      formData.append("trn_number", this.trnNumber);
      this.showLoader();
      this.$http
          .post("venues/profile/documents", formData, {
            headers: {
              "Content-Type": "multipart/form-data; boundary=${form._boundary}",
            },
          })
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.documentDialog = false;
              this.hideLoader();
              this.showSuccess("Licence details updated");
              this.$store.dispatch("loadVenueProfileDetails");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    updateAccountsDetail() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      event.preventDefault();
      this.showLoader();
      this.$http
          .post("venues/profile/bank-account", this.accountData)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.showSuccess("Bank account details updated");
              this.bank_dialog = false;
              this.hideLoader();
              this.$store.dispatch("loadVenueProfileDetails");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    closeAccountDetails() {
      this.bank_dialog = false;
      let data = this.$store.getters.getVenueDetails.data;
      let bank = data ? data.bank_details : {};
      this.accountData = bank;
      this.$forceUpdate();
      this.$refs.form.resetValidation();
    },
    updateBankAccount() {
      let data = this.$store.getters.getVenueDetails.data;
      let bank = data ? data.bank_details : {};
      this.accountData = JSON.parse(JSON.stringify(bank));
      this.bank_dialog = true;
    },
  },
};
</script>

<style scoped>
.v-btn--fab.v-btn--fixed,
.v-btn--fab.v-btn--absolute {
  z-index: 0 !important;
}

.documents .v-icon {
  border-radius: 12px;
  background: black;
}

.doc_image {
  margin: 40px;
}

.doc_btn {
  text-align: center;
}

.account {
  padding: 22px 10px;
  text-align: left;
  margin: 0 auto;
  width: 90%;
}

.title_txt {
  color: #fff;
  font-size: 14px;
  padding: 6px;
  background: #066a8c;
  margin: 5px;
  border-radius: 3px;
  text-align: center;
}

.card_title_text {
  color: #111111;
  font-size: 16px;
  padding: 6px;
  padding-left: 20px !important;
  margin: 5px;
  text-align: left;
}

.commercial {
  width: 50%;
}

.commercial div {
  padding: 6px;
}

#section4 .card {
  min-height: 230px;
}

.fleft {
  float: left;
}
</style>
