<template>
  <v-container fluid>
    <v-tabs
        v-model="tab"
        left
        light
        hide-slider
        class=" shadow-0 q-profile-tabs rounded"
        slider-color="transparent"
        style="width: fit-content;"
    >
      <v-tabs-slider></v-tabs-slider>
      <template v-for="(loopTab,index) in tabs">
        <v-tab
            v-if="loopTab.permission"
            :ripple="false"
            :key="loopTab.path"
            :class="{'tab':index <= tabs.length-1,'border-0':index === tabs.length-1}"
            :href="`#${loopTab.path}`"
        >
          <SvgIcon :text="loopTab.name"
                   class="text-sm font-normal"
                   :class="{
                    'qp-tab-nav-is-active':loopTab.path === tab,
                    'qp-tab-nav':loopTab.path !== tab
                  }"
          >
            <template v-slot:icon>
              <component :is="loopTab.icon" :component="loopTab.icon" width="20" height="20" viewBox="0 0 24 24" />
            </template>
          </SvgIcon>
        </v-tab>
      </template>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="profile">
        <div id="section1" class="section">
          <venue-details
              v-bind="profileForm"
              @updateVenue="editModal"
              v-bind:services="venueServices"
              @deleteService="deleteServiceConfirm"
              @addService="openAddServiceDialog"
          ></venue-details>
        </div>
      </v-tab-item>

      <v-tab-item value="documents">
        <div id="section4" class="section">
          <venue-docs></venue-docs>
        </div>
      </v-tab-item>

      <v-tab-item value="address">
        <div id="section6" class="section">
          <venue-address></venue-address>
        </div>
      </v-tab-item>

      <v-tab-item v-if="enableSMTP" value="smtp">
        <div id="section6" class="section">
          <MailChimpSMTP></MailChimpSMTP>
        </div>
      </v-tab-item>

      <v-tab-item v-if="enableSSO" value="azure">
        <div id="section6" class="section">
          <AzureAdSSO></AzureAdSSO>
        </div>
      </v-tab-item>

      <v-tab-item v-if="checkReadPermission($modules.general.ai_sensy.slug)" value="ai-sensy">
        <div id="section6" class="section">
          <AiSensy></AiSensy>
        </div>
      </v-tab-item>

    </v-tabs-items>

    <v-dialog v-model="profile_edit" scrollable width="40%" persistent>
      <v-form v-on:submit.prevent ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-text class="border-bottom mb-3">
            <div class="row pt-1 border-bottom">
              <div class="col-md-12">
                <div class="d-flex justify-space-between align-center mt-2">
                  <SvgIcon class="text-2xl font-semibold" text="Update Profile" style="color: black">
                  </SvgIcon>
                  <v-btn fab x-small class="shadow-0" @click="profile_edit = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <v-row class="mt-8" dense>
              <v-col md="12">
                <v-row justify="center">
                  <div class="col-lg-6 col-md-6 d-flex align-items-center justify-center">
                    <div style="width: 200px; height: 200px">
                      <image-uploader
                          @upload="
                          (data) => {
                            profileForm.image = data;
                          }
                        "
                          @remove="
                          () => {
                            profileForm.image = null;
                          }
                        "
                          :image_path="profileForm.profile_image"
                          :height="200"
                          text="Venue Image"
                          :show-guide="false"
                          message-text=""
                          ref="image_upload"
                          :contain="true"
                          defaultImage="default"
                      />
                    </div>
                  </div>
                </v-row>
              </v-col>
              <div class="row row--dense mt-2">
                <v-col cols="12" md="6">
                  <label for="">Venue Name</label>
                  <v-text-field
                      v-model="profileForm.name"
                      required
                      outlined
                      background-color="#fff"
                      :rules="[(v) => !!v || 'Name is required']"
                      validate-on-blur
                      class="q-text-field shadow-0"
                      hide-details="auto"
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <label for="">Phone Number</label>
                  <mobile-number-field
                      v-model="profileForm.phone"
                      :dense="true" :outlined="true" :variant="1"
                  ></mobile-number-field>
                </v-col>
                <v-col cols="12" md="6" class="pt-0">
                  <label for="">Venue Address</label>
                  <v-text-field
                      v-model="profileForm.display_address"
                      required
                      outlined
                      background-color="#fff"
                      :rules="[(v) => !!v || 'Address is required']"
                      validate-on-blur
                      class="q-text-field shadow-0"
                      hide-details="auto"
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pt-0">
                  <label for="">E-mail</label>
                  <v-text-field
                      v-model="profileForm.email"
                      :rules="[
                      (v) => !!v || 'E-mail is required',
                      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                    ]"
                      required
                      outlined
                      background-color="#fff"
                      validate-on-blur
                      class="q-text-field shadow-0"
                      hide-details="auto"
                      dense
                  ></v-text-field>
                </v-col>
                <v-col class="pt-0">
                  <label for="">About Venue</label>
                  <v-textarea
                      name="description"
                      v-model="profileForm.description"
                      outlined
                      background-color="#fff"
                      class="q-text-field shadow-0"
                      hide-details="auto"
                      dense
                      :rules="[(v) => !!v || 'Description is required']"
                  ></v-textarea>
                </v-col>
              </div>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                class="ma-2 "
                text
                @click="profile_edit = false"
            >Close
            </v-btn
            >
            <v-btn
                class="ma-2 white--text blue-color"
                text
                @click="updateProfile"
            >Update
            </v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="serviceDialog" scrollable max-width="450" persistent>
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-card-text class="border-bottom mb-3">
            <div class="row pt-1 border-bottom">
              <div class="col-md-12">
                <div class="d-flex justify-space-between align-center mt-2">
                  <SvgIcon class="text-2xl font-semibold" text="Add Service" style="color: black">
                  </SvgIcon>
                  <v-btn fab x-small class="shadow-0" @click="closeServiceForm">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <v-row class="mt-8" dense>
              <v-col md="12">
                <label for="">Select Category</label>
                <v-select
                    v-model="serviceType"
                    required
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    @change="resetCategorySelection"
                    :items="['Sports', 'Social']"
                    validate-on-blur
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    dense
                    :rules="[(v) => !!v || 'Please select the type']"
                ></v-select>
              </v-col>
              <v-col md="12">
                <label for="">Select Service</label>
                <v-select
                    v-model="service"
                    item-value="id"
                    item-text="name"
                    :items="serviceByType()"
                    @change="serviceChange"
                    required
                    return-object
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    :rules="[(v) => !!v || 'Categories is required']"
                    validate-on-blur
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    dense
                ></v-select>
              </v-col>
              <v-col
                  cols="md"
                  v-if="serviceType != null && service.name == 'Custom'"
              >
                <label for="">Service Name</label>
                <v-text-field
                    v-model="serviceForm.name"
                    label="Service Name"
                    required
                    outlined
                    background-color="#fff"
                    :rules="[(v) => !!v || 'Service Name is required']"
                    validate-on-blur
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" justify="center">
                <v-row
                    v-if="serviceImageUrl"
                    no-gutters
                    justify="center"

                >
                  <v-col md="3">
                    <v-card
                        flat

                        tile
                        class="d-flex"
                        elevation="0"
                    >
                      <v-img
                          :src="serviceImageUrl"
                          style="object-fit: contain; max-height: 120px"
                      />
                    </v-card>
                  </v-col
                  >
                </v-row>
              </v-col>
              <div
                  v-if="serviceType != null && service.name == 'Custom'"
                  style="min-height: 200px; max-height: 200px"
                  class="overflow-y-auto pa-3 overflow-x-hidden"
              >
                <v-row>
                  <v-col
                      md="3"
                      v-for="(image, index) in categoryImages"
                      :key="index"
                  >
                    <v-hover v-slot:default="{ hover }">
                      <v-card

                          tile
                          class="d-flex"
                          :elevation="hover ? 4 : 0"
                          @click="selectImage(image)"
                      >
                        <v-img
                            :src="s3BucketURL + image"
                            contain
                            aspect-ratio="1.1"
                        ></v-img>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </div>
              <v-col
                  cols="12"
                  v-if="serviceType != null && service.name == 'Custom'"
              >
                <v-row no-gutters justify="center">
                  <v-col md="5">
                    <image-uploader
                        @upload="
                        (data) => {
                          serviceForm.image = data;
                        }
                      "
                        @result="
                        (data) => {
                          serviceImageUrl = data;
                        }
                      "
                        @remove="
                        (data) => {
                          serviceForm.image = null;
                          serviceForm.map_pin = null;
                          serviceImageUrl = null;
                        }
                      "
                        :height="200"
                        text="Custom icon or image"
                        :show-guide="false"
                        message-text=""
                        ref="image_upload"
                        :contain="true"
                        defaultImage="default"

                    ></image-uploader>
                  </v-col
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                class="ma-2"
                text
                @click="closeServiceForm"
            >Close
            </v-btn
            >
            <v-btn class="ma-2 white--text teal-color" text @click="addService"
            >Add
            </v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>

<script>
import VenueDetails from "./Tabs/VenueDetails";
import VenueDocs from "./Tabs/VenueDocs";
// import KrewsPosts from "./Tabs/KrewsPosts";
import VenueAddress from "./Tabs/VenueAddress";
import MailChimpSMTP from "./Tabs/MailChimpSMTP";
import AiSensy from "./Tabs/AiSensy.vue";
import AzureAdSSO from "./Tabs/AzureAdSSO";
import ConfirmModel from "@/components/Confirmation/ConfirmModel";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ImageUploader from "@/components/Image/ImageUploader.vue";
import MobileNumberField from "@/components/Fields/MobileNumberField.vue";
import ProfileIcon from "@/assets/images/profile/profile.svg";
import DocumentsIcon from "@/assets/images/profile/documents.svg";
import AddressIcon from "@/assets/images/profile/location.svg";
import smtpIcon from "@/assets/images/profile/smtp.svg";
import AzureIcon from "@/assets/images/profile/azure.svg";
import wsIcon from "@/assets/images/profile/ws.svg";

export default {
  name: "Profile",
  components: {
    ProfileIcon,
    DocumentsIcon,
    AddressIcon,
    smtpIcon,
    AzureIcon,
    wsIcon,
    MobileNumberField,
    ImageUploader,
    SvgIcon,
    VenueDetails,
    VenueDocs,
    VenueAddress,
    MailChimpSMTP,
    ConfirmModel,
    AiSensy,
    AzureAdSSO,
  },
  data() {
    return {
      enableSMTP: false,
      enableSSO: false,
      enableAiSensy: true,
      tab: null,
      valid: true,
      profile: {},
      venueServices: [],
      profile_edit: false,
      profileForm: {},
      serviceDialog: false,
      serviceForm: {},
      serviceImageUrl: false,
      services: [],
      serviceType: null,
      service: {},
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
    };
  },
  computed: {
    categoryImages() {
      return this.services
          .filter((item) => item.image_path != "" && item.image_path != null)
          .map((item) => item.image_path);
    },
    tabs(){
      return [
        {
          name: "Profile",
          icon: ProfileIcon,
          path:'profile',
          permission: this.checkReadPermission(this.$modules.general.profile.slug),
        },
        {
          name: "Documents",
          icon: DocumentsIcon,
          path: 'documents',
          permission: this.checkReadPermission(this.$modules.general.profile.slug),
        },
        {
          name: "Address",
          icon: AddressIcon,
          path: 'address',
          permission: this.checkReadPermission(this.$modules.general.profile.slug),
        },
        {
          name: "SMTP Configuration",
          icon: smtpIcon,
          path: 'smtp',
          permission: this.enableSMTP && this.checkReadPermission(this.$modules.general.profile.slug),
        },
        {
          name: "Azure AD configuration",
          icon: AzureIcon,
          path: 'azure',
          permission: this.enableSSO,
        },
        {
          name: "AI Sensy",
          icon: wsIcon,
          path: 'ai-sensy',
          permission: this.checkReadPermission(this.$modules.general.ai_sensy.slug),
        }
      ];
    }
  },
  created() {
    this.getProfileDetails();
    this.getGlobalServies();
  },
  methods: {
    getProfileDetails() {
      this.$store.dispatch("loadVenueProfileDetails").then(() => {
        const data = this.$store.getters.getVenueDetails.data;
        console.log(data);
        this.venueServices = JSON.parse(JSON.stringify(data.services));
        this.profileForm = JSON.parse(JSON.stringify(data.profile));
        this.enableSMTP = data.enable_smtp ? data.enable_smtp : 0;
        this.enableSSO = data.enable_azure_ad_sso
            ? data.enable_azure_ad_sso
            : 0;
      });
    },
    getGlobalServies() {
      this.$http
          .get("services/global")
          .then((response) => {
            if (response.status == 200) {
              this.services = response.data.data;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    serviceChange() {
      this.serviceForm.service_id = this.service.id;
      if (this.serviceForm.service_id != null) {
        this.serviceForm.name = this.service.name;
        this.serviceForm.default_image = this.service.image_path;
        this.serviceImageUrl =
            this.serviceForm.default_image != null &&
            this.serviceForm.default_image != ""
                ? this.s3BucketURL + this.serviceForm.default_image
                : null;
      } else {
        this.serviceForm.name = null;
        this.serviceForm.default_image = null;
        this.serviceImageUrl = null;
      }
    },
    resetCategorySelection() {
      this.service = {};
      this.serviceForm.serviceForm = null;
      this.serviceForm.name = null;
      this.serviceForm.default_image = null;
    },
    selectImage(image) {
      this.serviceForm.default_image = image;
      this.serviceImageUrl = this.s3BucketURL + this.serviceForm.default_image;
    },
    updateProfile() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader();
      event.preventDefault();
      let formData = new FormData();
      Object.keys(this.profileForm).forEach((key) => {
        let value = this.profileForm[key];
        if(key == "description"){
          value = btoa(this.profileForm[key]);
        }
        formData.append(key, value);
      });
      this.$http
          .post("venues/profile", formData, {
            headers: {
              "Content-Type": "multipart/form-data; boundary=${form._boundary}",
            },
          })
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.profile_edit = false;
              this.showSuccess("Venue Details Updated Successfully.");
              this.hideLoader();
              this.getProfileDetails();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    editModal() {
      this.profile_edit = true;
    },
    deleteServiceConfirm(data) {
      this.confirmModel = {
        id: data,
        title: "Do you want to delete this service?",
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "delete_service",
      };
    },
    confirmActions(data) {
      if (data.type == "delete_service") {
        this.deleteService(data.id);
      }
      this.confirmModel.id = null;
    },
    deleteService(id) {
      this.showLoader();
      this.$http
          .delete(`venues/services/${id}`)
          .then((response) => {
            if (response.status == 200) {
              this.showSuccess("Service Deleted Successfully.");
              this.hideLoader();
              this.getProfileDetails();
              this.$store.dispatch("loadVenueServices");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    openAddServiceDialog() {
      this.serviceForm = {};
      this.serviceType = null;
      this.service = null;
      this.serviceDialog = true;
      this.serviceImageUrl = null;
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
    addService() {
      if (!this.$refs.form.validate()) return;
      if (this.serviceImageUrl == null) {
        this.showError("Select any image");
        return;
      }
      this.showLoader();
      event.preventDefault();
      let formData = new FormData();
      Object.keys(this.serviceForm).forEach((key) => {
        let value = this.serviceForm[key];
        if (value != null) {
          formData.append(key, value);
        }
      });

      this.$http
          .post("venues/services", formData)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.serviceDialog = false;
              this.showSuccess("Venue Details Updated Successfully.");
              this.hideLoader();
              this.getProfileDetails();
              this.$store.dispatch("loadVenueServices");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    closeServiceForm() {
      this.serviceDialog = false;
      this.$refs.form.resetValidation();
    },
    serviceByType() {
      if (this.serviceType != null) {
        let type = this.serviceType.toLowerCase();
        let data = this.services
            .filter((item) => {
              return item.service_type == type;
            })
            .sort((a, b) => a.order - b.order);
        data = [{id: null, name: "Custom"}, ...data];
        return data;
      }
      return [];
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/variables";

.v-btn--active .v-btn__content .v-icon {
  color: #66c8c8 !important;
}

.section {
  margin: 20px 0 50px 0;
  padding: 20px 0 20px;
}

.profile_title {
  padding: 10px;
  text-align: center;
}

.profile_title h1 {
  color: #066a8c;
}

.v-item-group.v-bottom-navigation {
  top: 64px !important;
  z-index: 1;
}

.nav {
  position: relative;
}

.layout.wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 20px;
}

.v-item-group.v-bottom-navigation {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.theme--light.v-bottom-navigation {
  background-color: #edf9ff;
  height: 75px !important;
}

.v-item-group.v-bottom-navigation--grow .v-btn {
  height: 73px;
  padding-top: 10px;
}

.v-bottom-navigation span {
  font-size: 13px;
  padding: 10px;
  text-align: center;
}

.active_tab_profile_fill_icon {
  color: #000000 !important;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  svg {
    opacity: 1 !important;
  }
}

.v-btn__content,
.v-btn--fab.v-btn--absolute {
  z-index: 0 !important;
}

.v-btn--fab.v-btn--absolute {
  z-index: 4;
}

.theme--light.v-tabs-items {
  background-color: transparent;
}

.section {
  margin: 20px 0 50px 0;
  padding: 20px 0 20px;
}

.v-tabs__item--active {
  background: #fff;
}

.v-tab {
  min-width: 150px;
}

.v-tab--active {
  background: #fff !important;
  color: rgb(6, 106, 140);
}

.q-profile-tabs {
  background: #fff;
  color: #00000066;
  border: 1px solid #EAEAEA !important;
}

.tab {
  text-transform: capitalize;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: fit-content;
  justify-content: space-around;
  white-space: nowrap !important;
  max-width: unset !important;
  &:not(.border-0):after{
    border-right: 1px solid #DCDCDC;
    content: " ";
    height: 24px;
  }
  &,& *{
    &:before{
      background: transparent !important;
    }
  }
}
.q-tabs-secondary-profile{
  border-bottom:transparent ;
}


</style>
