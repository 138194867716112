<template>
  <v-layout row wrap mr-5 ml-5>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="6">
          <v-card class="card">
            <v-card-text class="pt-1 pb-5">
              <div class="row pt-1 border-bottom">
                <div class="col-md-12">
                  <div class="d-flex justify-space-between align-center mt-2">
                    <SvgIcon class="text-xl font-semibold" text="Azure AD configurations" style="color: black">
                    </SvgIcon>
                    <v-btn fab x-small class="shadow-0" v-if="
                          checkWritePermission($modules.general.profile.slug)
                        " @click.stop="showSMTPPopup">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
              <v-layout row wrap>
                <v-flex sm12>
                  <div class="account" style="text-align: left">
                    <div class="fleft commercial">
                      <div><strong>Client ID</strong></div>
                      <div><strong>Tenant</strong></div>
                      <div><strong>Tenant ID</strong></div>
                      <div><strong>Authority</strong></div>
                      <div><strong>Status</strong></div>
                    </div>

                    <div class="fleft commercial">
                      <div>{{ adConfig.clientId || "NA" }}</div>
                      <div>{{ adConfig.tenant || "NA" }}</div>
                      <div>{{ adConfig.tenantId || "NA" }}</div>
                      <div>{{ adConfig.authority || "NA" }}</div>
                      <div>
                        {{
                          adConfig.status_id
                            ? adConfig.status_id == 1
                              ? "ACTIVE"
                              : "INACTIVE"
                            : "NA"
                        }}
                      </div>
                    </div>

                    <div class="clearfix"></div>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- SMTP Details  Start-->
    <v-dialog v-model="ad_dialog" scrollable max-width="650" persistent>
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-card-text class="border-bottom mb-3">
            <div class="row pt-1 border-bottom">
              <div class="col-md-12">
                <div class="d-flex justify-space-between align-center mt-2">
                  <SvgIcon class="text-2xl font-semibold"
                           text="SSO configuration"
                           style="color: black">
                  </SvgIcon>
                  <v-btn fab x-small class="shadow-0" @click="closeAccountDetails">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <v-row class="mt-8" dense>
              <v-col cols="12" md="12">
                <label for="">Tenant</label>
                <v-text-field
                  v-model="adConfig.tenant"
                  outlined
                  background-color="#fff"
                  required
                  :rules="[(v) => !!v || 'Tenant is required']"
                  validate-on-blur
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <label for="">Client ID*</label>
                <v-text-field
                  v-model="adConfig.clientId"
                  required
                  outlined
                  background-color="#fff"
                  :rules="[(v) => !!v || 'Client Id is required']"
                  validate-on-blur
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <label for="">Tenant ID*</label>
                <v-text-field
                  v-model="adConfig.tenantId"
                  outlined
                  background-color="#fff"
                  required
                  :rules="[(v) => !!v || 'Tenant ID is required']"
                  validate-on-blur
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <label for="">Authority*</label>
                <v-text-field
                  v-model="adConfig.authority"
                  outlined
                  background-color="#fff"
                  required
                  :rules="[(v) => !!v || 'Authority is required']"
                  validate-on-blur
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <label for="">Select Status</label>
                <v-select
                  v-model="adConfig.status_id"
                  item-value="id"
                  item-text="name"
                  required
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                  :items="[
                    { id: 1, name: 'Active' },
                    { id: 2, name: 'Inactive' },
                  ]"
                  :rules="[(v) => !!v || 'Please select the status']"
                  validate-on-blur
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-2 "
              text
              @click="closeAccountDetails"
              >Close</v-btn
            >
            <v-btn
              class="ma-2 white--text blue-color"
              text
              @click="updateSMTPDetail"
              >Update</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- SMTP Details end-->
  </v-layout>
</template>

<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  components: {SvgIcon},
  data() {
    return {
      ad_dialog: false,
      valid: true,
      document: {},
      adConfig: {},
    };
  },
  mounted() {
    if (this.$store.getters.getVenueDetails.status) {
      console.log(this.$store.getters.getVenueDetails.data);
      this.adConfig = this.$store.getters.getVenueDetails.data.ad_config
        ? this.$store.getters.getVenueDetails.data.ad_config
        : {};
    }
  },
  methods: {
    updateSMTPDetail() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      event.preventDefault();
      this.showLoader();
      this.$http
        .post("venues/profile/ad-sso", this.adConfig)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.showSuccess("SSO configuration updated");
            this.ad_dialog = false;
            this.hideLoader();
            this.$store.dispatch("loadVenueProfileDetails");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    showSMTPPopup() {
      let data = this.$store.getters.getVenueDetails.data;
      let smtp = data ? data.ad_config : {};
      if (smtp) {
        this.adConfig = JSON.parse(JSON.stringify(smtp));
      } else {
        this.adConfig = {};
      }
      this.ad_dialog = true;
    },

    closeAccountDetails() {
      this.ad_dialog = false;
      let data = this.$store.getters.getVenueDetails.data;
      let smtp = data ? data.ad_config : {};
      if (smtp) {
        this.adConfig = JSON.parse(JSON.stringify(smtp));
      } else {
        this.adConfig = {};
      }
      this.$forceUpdate();
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
.form_bg {
  margin-top: 2px;
}
.v-btn--fab.v-btn--fixed,
.v-btn--fab.v-btn--absolute {
  z-index: 0 !important;
}

.documents .v-icon {
  border-radius: 12px;
  background: black;
}
.doc_image {
  margin: 40px;
}
.doc_btn {
  text-align: center;
}
.account {
  padding: 22px 10px;
  text-align: left;
  margin: 0 auto;
  width: 90%;
}
.title_txt {
  color: #fff;
  font-size: 14px;
  padding: 6px;
  background: #066a8c;
  margin: 5px;
  border-radius: 3px;
  text-align: center;
}
.commercial {
  width: 50%;
}
.commercial div {
  padding: 6px;
}
#section4 .card {
  min-height: 230px;
}
.fleft {
  float: left;
}
</style>
