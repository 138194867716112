<template>
  <v-layout row wrap mr-5 ml-5>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="6">
          <v-card class="card">
            <v-card-text class="pt-1 pb-5">
              <div class="row pt-1 border-bottom">
                <div class="col-md-12">
                  <div class="d-flex justify-space-between align-center mt-2">
                    <SvgIcon class="text-xl font-semibold" text="SMTP details" style="color: black">
                    </SvgIcon>
                    <v-btn fab x-small class="shadow-0" @click.stop="showSMTPPopup">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
              <v-layout wrap>
                <v-flex sm12>
                  <div class="account" style="text-align: left">
                    <div class="fleft commercial">
                      <div><strong>SMTP Status</strong></div>
                      <div><strong>SMTP Host</strong></div>
                      <div><strong>SMTP Port</strong></div>
                      <div><strong>SMTP Username</strong></div>
                      <div><strong>SMTP Password</strong></div>
                      <div><strong>SMTP Encryption</strong></div>
                      <div><strong>Sender Email</strong></div>
                      <div><strong>Sender Name</strong></div>
                    </div>

                    <div class="fleft commercial">
                      <div>{{ smtpData.status_id ? smtpData.status_id == 1 ? "ACTIVE" : "DEACTIVE" : "NA" }}</div>
                      <div>{{ smtpData.host || "NA" }}</div>
                      <div>{{ smtpData.port || "NA" }}</div>
                      <div>{{ smtpData.username || "NA" }}</div>
                      <div>{{ smtpData.password || "NA" }}</div>
                      <div>{{ smtpData.encryption || "NA" }}</div>
                      <div>{{ smtpData.sender_email || "NA" }}</div>
                      <div>{{ smtpData.sender_name || "NA" }}</div>
                    </div>

                    <div class="clearfix"></div>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- SMTP Details  Start-->
    <v-dialog v-model="smtp_dialog" scrollable max-width="650" persistent>
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-card-text class="border-bottom mb-3">
            <div class="row pt-1 border-bottom">
              <div class="col-md-12">
                <div class="d-flex justify-space-between align-center mt-2">
                  <SvgIcon class="text-2xl font-semibold"
                           text="SMTP Details"
                           style="color: black">
                  </SvgIcon>
                  <v-btn fab x-small class="shadow-0" @click="closeAccountDetails">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <v-row class="mt-8" dense>
              <v-col cols="12" md="6">
                <label for="">Host Name</label>
                <v-text-field
                    v-model="smtpData.host"
                    required
                    outlined
                    background-color="#fff"
                    :rules="[(v) => !!v || 'Host Name is required']"
                    validate-on-blur
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <label for="">SMTP Port ex. 465 or 587*</label>
                <v-text-field
                    v-model="smtpData.port"
                    outlined
                    background-color="#fff"
                    required
                    :rules="[
                        (v) => !!v || 'SMTP Port is required',
                        (v) =>
                            (v && (v == 465 || v == 587)) ||
                            'SMTP Port should be 587 for tls or 465 for ssl',
                        ]"
                    validate-on-blur
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <label for="">SMTP Username*</label>
                <v-text-field
                    v-model="smtpData.username"
                    outlined
                    background-color="#fff"
                    required
                    :rules="[(v) => !!v || 'SMPT Username is required']"
                    validate-on-blur
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <label for="">SMTP Password*</label>
                <v-text-field
                    v-model="smtpData.password"
                    outlined
                    background-color="#fff"
                    required
                    :rules="[ (v) => !!v || 'SMTP Password is required',]"
                    validate-on-blur
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <label for="">SMTP Encryption ex. ssl or tls*</label>
                <v-text-field
                    v-model="smtpData.encryption"
                    outlined
                    background-color="#fff"
                    required
                    :rules="[
                          (v) => !!v || 'SMTP Encryption is required',
                          (v) =>
                            (v && (v == 'ssl' || v == 'tls')) ||
                            'SMTP encryption should be ssl or tls',
                        ]"
                    validate-on-blur
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <label for="">Sender Email*</label>
                <v-text-field
                    v-model="smtpData.sender_email"
                    outlined
                    background-color="#fff"
                    required
                    :rules="[(v) => !!v || 'Sender Email is required']"
                    validate-on-blur
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <label for="">Sender Name*</label>
                <v-text-field
                    v-model="smtpData.sender_name"
                    outlined
                    background-color="#fff"
                    required
                    :rules="[(v) => !!v || 'Sender Name is required']"
                    validate-on-blur
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <label for="">Select Status</label>
                <v-select
                    v-model="smtpData.status_id"
                    item-value="id"
                    item-text="name"
                    required
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    :items="[{ id:1,name:'Activate'},{id:2,name:'Deactivate'}]"
                    :rules="[(v) => !!v || 'Please select the status']"
                    validate-on-blur
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    dense
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                class="ma-2 white--text blue-color"
                text
                @click="closeAccountDetails"
            >Close
            </v-btn
            >
            <v-btn
                class="ma-2 white--text teal-color"
                text
                @click="updateSMTPDetail"
            >Update
            </v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- SMTP Details end-->
  </v-layout>
</template>

<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  components: {SvgIcon},
  data() {
    return {
      smtp_dialog: false,
      valid: true,
      document: {},
      smtpData: {},
    };
  },
  mounted() {
    if (this.$store.getters.getVenueDetails.status) {
      this.smtpData = this.$store.getters.getVenueDetails.data.smtp_details ? this.$store.getters.getVenueDetails.data.smtp_details : {};
    }
  },
  methods: {
    updateSMTPDetail() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      event.preventDefault();
      this.showLoader();
      this.$http
          .post("venues/profile/smtp-account", this.smtpData)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.showSuccess("SMTP account details updated");
              this.smtp_dialog = false;
              this.hideLoader();
              this.$store.dispatch("loadVenueProfileDetails");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    showSMTPPopup() {
      let data = this.$store.getters.getVenueDetails.data;
      let smtp = data ? data.smtp_details : {};
      if (smtp) {
        this.smtpData = JSON.parse(JSON.stringify(smtp));
      } else {
        this.smtpData = {};
      }
      this.smtp_dialog = true;
    },

    closeAccountDetails() {
      this.smtp_dialog = false;
      let data = this.$store.getters.getVenueDetails.data;
      let smtp = data ? data.smtp_details : {};
      if (smtp) {
        this.smtpData = JSON.parse(JSON.stringify(smtp));
      } else {
        this.smtpData = {};
      }
      this.$forceUpdate();
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
.form_bg {
  margin-top: 2px;
}

.v-btn--fab.v-btn--fixed,
.v-btn--fab.v-btn--absolute {
  z-index: 0 !important;
}

.documents .v-icon {
  border-radius: 12px;
  background: black;
}

.doc_image {
  margin: 40px;
}

.doc_btn {
  text-align: center;
}

.account {
  padding: 22px 10px;
  text-align: left;
  margin: 0 auto;
  width: 90%;
}

.title_txt {
  color: #fff;
  font-size: 14px;
  padding: 6px;
  background: #066a8c;
  margin: 5px;
  border-radius: 3px;
  text-align: center;
}

.commercial {
  width: 50%;
}

.commercial div {
  padding: 6px;
}

#section4 .card {
  min-height: 230px;
}

.fleft {
  float: left;
}
</style>
