<template>
  <v-layout row wrap mr-5 ml-5>
    <v-row>
      <v-col cols="4">
        <v-card class="fill-height">
          <v-card-text class="pa-1">
            <div class="row pt-1 border-bottom">
              <div class="col-md-12">
                <div class="d-flex justify-space-between align-center mt-2">
                  <SvgIcon class="text-xl font-semibold" text="Heat Map" style="color: black" >
                  </SvgIcon>
                  <v-btn fab x-small class="shadow-0" @click.stop="openHeadMapModel">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
              <v-row justify="center">
                <v-avatar rounded size="250">
                  <view-image
                    :image="addressDetails.heat_map_image"
                    defaultImage="map"
                    :contain="false"
                  ></view-image>
                </v-avatar>
              </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="8">
        <v-card class="fill-height">
          <v-card-text class="pa-1">
            <div class="row pt-1 border-bottom">
              <div class="col-md-12">
                <div class="d-flex justify-space-between align-center mt-2">
                  <SvgIcon class="text-lg font-semibold" :text="'Location : '+ (addressDetails.address || 'NA') " style="color: black" >
                  </SvgIcon>
                </div>
              </div>
            </div>
              <v-flex sm12 style="margin-top:26px">
                <GmapMap
                  v-bind:center="{
                    lat: parseFloat(addressForm.latitude || center.lat),
                    lng: parseFloat(addressForm.longitude || center.lng),
                  }"
                  :zoom="10"
                  map-type-id="terrain"
                  ref="map"
                  style="width: 100%; height: 300px; margin-bottom: 13px"
                >
                  <GmapMarker
                    ref="mapRef"
                    :position="{
                      lat: parseFloat(addressForm.latitude || center.lat),
                      lng: parseFloat(addressForm.longitude || center.lng),
                    }"
                    :clickable="true"
                  />
                </GmapMap>
              </v-flex>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="addressDialog" scrollable max-width="650" persistent>
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-card-text class="border-bottom mb-3">
            <div class="row pt-1 border-bottom">
              <div class="col-md-12">
                <div class="d-flex justify-space-between align-center mt-2">
                  <SvgIcon class="text-xl font-semibold" text="Add Location & HeatMap" style="color: black" >
                  </SvgIcon>
                  <v-btn  fab x-small class="shadow-0" @click="closeHeatmap">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <v-col>
              <GmapMap
                v-bind:center="{
                  lat: parseFloat(addressForm.latitude),
                  lng: parseFloat(addressForm.longitude),
                }"
                :zoom="12"
                map-type-id="terrain"
                style="width: 100%; height: 200px"
              >
                <GmapMarker
                  ref="mapRef"
                  :position="{
                    lat: parseFloat(addressForm.latitude),
                    lng: parseFloat(addressForm.longitude),
                  }"
                  :clickable="true"
                  :draggable="true"
                  @dragend="updateCoordinates"
                />
              </GmapMap>
            </v-col>
            <v-row class="mt-8" dense >
              <v-col md="12">
                <label for="">Location</label>
                <v-autocomplete
                  v-model="autocompleteLocationModel"
                  :items="locationEntries"
                  :search-input.sync="locationSearchText"
                  item-text="value"
                  item-value="value"
                  hide-no-data
                  :loading="isLoading"
                  outlined
                  background-color="#fff"
                  @change="changeLocation"
                  validate-on-blur
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  dense
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <label for="">Latitude</label>
                <v-text-field
                  v-model="addressForm.latitude"
                  required
                  readonly
                  outlined
                  background-color="#fff"
                  validate-on-blur
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <label for="">Longitude</label>
                <v-text-field
                  v-model="addressForm.longitude"
                  required
                  readonly
                  outlined
                  background-color="#fff"
                  validate-on-blur
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <label for="">Select Country</label>
                <v-select
                  v-model="addressForm.country_id"
                  :items="countries"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                  item-text="name"
                  item-value="id"
                  :rules="[(v) => !!v || 'Country is required']"
                  validate-on-blur
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <label for="">Choose City</label>
                <v-select
                  v-model="addressForm.city_id"
                  :items="cities"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                  item-text="name"
                  item-value="id"
                  dense
                  :rules="[(v) => !!v || 'City is required']"
                  validate-on-blur
                  class="q-text-field shadow-0"
                  hide-details="auto"
                ></v-select>
              </v-col>

              <v-col>
                <v-row no-gutters justify="center">
                  <v-col md="6"
                    ><image-upload
                      @upload="
                        (data) => {
                          addressForm.heat_map_image = data;
                        }
                      "
                      @remove="
                        () => {
                          addressForm.heat_map_image = null;
                        }
                      "
                      :image_path="addressDetails.heat_map_image_path"
                      :height="160"
                      defaultImage="map"
                    ></image-upload
                  ></v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-2"
              text
              @click="closeHeatmap"
              >Close</v-btn
            >
            <v-btn
              class="ma-2 white--text blue-color"
              text
              @click="updateAddress"
              >Update</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- Profile Edit end-->
    <v-dialog v-model="locationDialoge" persistent width="40%">
      <v-card>
        <v-card-title class="headline">Select Location</v-card-title>
        <v-card-text class="pa-5">
          <v-row>
            Please select a location from the list. If you don't see correct
            location name in the list below try changing location on map by
            dragging the marker or clicking on the preffered location.
          </v-row>
          <v-row>
            <v-select
              :items="mapLocations"
              item-text="formatted_address"
              item-value="formatted_address"
              v-model="addressData"
            >
            </v-select>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dragMapChange">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { GetSuggestions, placeDetails } from "@/utils/placesUtils";
import SvgIcon from "@/components/Image/SvgIcon.vue";
export default {
  components: {SvgIcon},
  data() {
    return {
      center: { lat: 24.46436049078158, lng: 54.37532545660189 },
      addressDialog: false,
      valid: true,
      addressForm: {},
      cities: [],
      mapLocations: [],
      isLoading: false,
      locationDialoge: false,
      autocompleteLocationModel: null,
      locationSearchText: null,
      locationEntries: [],
      addressData: "",
    };
  },
  mounted() {
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    this.setAddressData();
    this.getCitiesData();
  },
  computed: {
    countries() {
      return this.$store.getters.getCountries.data;
    },

    addressDetails() {
      if (this.$store.getters.getVenueDetails.status == false) return {};
      let data = this.$store.getters.getVenueDetails.data;
      return data ? data.address : {};
    },
  },
  watch: {
    locationSearchText(newVal) {
      var _vue = this;
      if (
        newVal == null ||
        newVal.length == 0 ||
        this.autocompleteLocationModel == newVal
      )
        return;
      this.isLoading = true;
      GetSuggestions(newVal)
        .then(function (res) {
          _vue.isLoading = false;
          _vue.locationEntries = res;
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
  methods: {
    openHeadMapModel() {
      this.addressDialog = true;
      this.setAddressData();
    },
    setAddressData() {
      this.addressForm.address =
        this.address != null && this.address != "" ? this.address : null;
      if (this.address != null) {
        this.locationEntries = [{ value: this.address, id: "0" }];
        this.autocompleteLocationModel = this.address;
        this.locationSearchText = this.address;
      }
      this.addressForm.latitude =
        this.addressDetails.latitude != null
          ? this.addressDetails.latitude
          : this.center.lat;
      this.addressForm.longitude =
        this.addressDetails.longitude != null
          ? this.addressDetails.longitude
          : this.center.lng;
      this.addressForm.country_id =
        this.addressDetails.country_id != null
          ? this.addressDetails.country_id
          : null;
      this.addressForm.city_id =
        this.addressDetails.city_id != null
          ? this.addressDetails.city_id
          : null;
      this.addressForm.heat_map_image_path = this.addressDetails.heat_map_image
        ? this.addressDetails.heat_map_image
        : null;
    },
    updateCoordinates(e) {
      let lat = e.latLng.lat();
      let lng = e.latLng.lng();
      this.addressForm.latitude = lat;
      this.addressForm.longitude = lng;
      /* global google */
      let geocoder = new google.maps.Geocoder();
      var locationss = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      };
      let that = this;
      geocoder.geocode({ location: locationss }, function (results, status) {
        if (status === "OK") {
          if (results.length > 0) {
            that.mapLocations = results;
            that.addressData = results[0].formatted_address;
            that.locationDialoge = true;
            that.addressDialog = false;
          }
        }
      });
    },
    changeLocation() {
      this.addressData = this.autocompleteLocationModel;
      this.addressForm.address = this.autocompleteLocationModel;
      let placeId = this.locationEntries.find(
        (val) => val.value == this.autocompleteLocationModel
      ).id;
      placeDetails(placeId).then((data) => {
        var lat = data[0].geometry.location.lat();
        var lng = data[0].geometry.location.lng();
        this.addressForm.latitude = lat;
        this.addressForm.longitude = lng;
        this.$forceUpdate();
      });
    },
    dragMapChange() {
      this.locationEntries = [{ value: this.addressData, id: "0" }];
      this.autocompleteLocationModel = this.addressData;
      this.locationSearchText = this.addressData;
      this.addressForm.address = this.addressData;
      this.locationDialoge = false;
      this.addressDialog = true;
    },
    closeHeatmap() {
      this.$refs.form.resetValidation();
      this.addressDialog = false;
    },
    updateAddress() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      event.preventDefault();
      this.showLoader();
      let formData = new FormData();
      Object.keys(this.addressForm).forEach((key) => {
        let value = this.addressForm[key];
        if (this.addressForm[key] != null) {
          formData.append(key, value);
        }
      });
      this.$http
        .post("venues/profile", formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=${form._boundary}",
          },
        })
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.addressDialog = false;
            this.hideLoader();
            this.$store.dispatch("loadVenueProfileDetails");
            this.showSuccess("Address Updated Successfully.");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getCitiesData() {
      this.$http
        .get("cities")
        .then((response) => {
          if (response.status == 200) {
            this.cities = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};
</script>
<style scoped>
.vue-map-container {
  width: 97% !important;
  margin: auto;
}
.title_txt {
  color: #fff;
  font-size: 14px;
  padding: 6px;
  background: #066a8c;
  margin: 5px;
  border-radius: 3px;
  text-align: center;
}
#section6 .layout {
  min-height: 244px;
}
.location {
  font-weight: 400;
  color: white;
}
.avatharImg {
  width: 100px;
  height: 100px;
  margin: auto;
  border-radius: 100px;
}
.profile_txt {
  text-align: center;
  font-size: 20px;
  color: #00b0af;
}
</style>
